<template>
  <div class="text-container">
    <div>
      <p>
        Sowohl die Gründung einer neuen Apotheke, als auch der Verkauf der
        selbst betriebenen Apotheke bedarf ausführlicher Vorbereitung. In diesem
        Bereich können wir Ihnen helfen, Fehler zu vermeiden und von Beginn an
        auf unsere Expertise in diesem Bereich zurückzugreifen.
      </p>
      <div>Wir unterstützen Sie hierzu in den folgenden Bereichen:</div>
    </div>
    <div class="pt-2">
      <div
        v-for="service in getChildsOfSite('standortgruendung')"
        :key="service.name"
        class="my-1"
      >
        <a :href="getSitePath(service.name)" class="service-link">
          <b-icon :icon="service.icon" class="mr-3"></b-icon>
          <span>{{ service.label.replace("[shy]", "") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import { getChildsOfSite, getSitePath } from "../../config/site-structure";

export default {
  methods: { getChildsOfSite, getSitePath },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir beraten bei der Gründung und dem Verkauf von Apotheken. Mit unserer Expertise helfen wir Ihnen Fehler zu vermeiden.",
      },
    ],
  },
};
</script>

<style scoped>
.service-link {
  color: #2c3e50;
}
</style>